<template>
  <div id="app">
    <div class="sidebar">
      <ul>
        <router-link tag="li" :to="{ path: '/' }">
          <i class="fa fa-home"></i> Home</router-link
        >
        <router-link tag="li" :to="{ path: '/company' }"> PPT Laos</router-link>
        <router-link tag="li" :to="{ path: '/108jobs' }"> 108Jobs</router-link>
        <router-link tag="li" :to="{ path: '/champa' }"> Champa</router-link>
        <router-link tag="li" :to="{ path: '/assessment' }">
          Assessment</router-link
        >
        <router-link tag="li" :to="{ path: '/hwPayslip' }">
          HW-Payslip</router-link
        >
        <!-- <router-link tag="li" :to="{ path: '/Tobacco' }"> Tobacco </router-link> -->
      </ul>
    </div>
    <div class="content">
      <router-view />
    </div>
  </div>
</template>
<script>
export default {
  data: () => ({
    companies: [
      {
        id: "01",
        name: "PPT Laos",
      },
      {
        id: "02",
        name: "Tobacco Laos",
      },
    ],
  }),
};
</script>
<style lang="scss">
body,
html {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  height: 100%;
  min-height: 100%;
}
#app {
  font-family: Noto Sans Lao, Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: $font-color;
  height: 100%;
  background-color: #fff;
  display: grid;
  grid-template-columns: 200px 1fr;
  .sidebar {
    background-color: #f4f5f4;
    border-right: 1px solid $border-color;
    display: flex;
    padding: 20px 30px;
    position: relative;
    ul {
      margin: 0;
      padding: 0;
      li {
        padding: 10px 0;
        text-align: left;
        list-style-type: none;
        font-size: 18px;
        cursor: pointer;
        &.router-link-exact-active {
          color: $primary-color;
          font-weight: 700;
        }
      }
    }
  } // sidebar
  .content {
    text-align: left;
    padding: 40px;
  }
}
</style>
