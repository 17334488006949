import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'index',
    component: () => import('../views/Index.vue')
  },
  {
    path: '/assessment',
    name: 'Assessment',
    component:() =>import('../views/Assessment.vue')
    },
    {
      path: '/108jobs',
      name: '108jobs',
      component:() =>import('../views/108jobs.vue')
      },
      {
        path: '/champa',
        name: 'Champa',
        component:() =>import('../views/Champa.vue')
        },
  
  // {
  //   path: '/company/:id',
  //   name: 'Company',
  //   component: () => import('../views/Company.vue')
  // },
  {
    path: '/company',
    name: 'Company',
    component: () => import('../views/Company.vue')
  },
  {
    path: '/userinfo',
    name: 'Employee',
    component: () => import('../views/Employee.vue')
  },
  {
    path: '/hwPayslip',
    name: 'HwPayslip',
    component: () => import('../views/Hw-payslip.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
