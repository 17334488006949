import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import Axios from "axios";
import "./plugins/Axios";
import './assets/formulate.css'
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
// import VueFormGenerator from "vue-form-generator";
import VueFormulate from '@braid/vue-formulate'

Vue.use(VueFormulate)
// Vue.use(VueFormGenerator);

Vue.use(VueSweetalert2);
Vue.config.productionTip = false;
Vue.prototype.$Axios = Axios;
new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
